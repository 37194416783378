import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import {
  ApplicationShell,
  createApolloClient,
  setupGlobalErrorListener,
  selectProjectKeyFromUrl,
  entryPointUriPathToPermissionKeys,
} from '@commercetools-frontend/application-shell';
import { Sdk } from '@commercetools-frontend/sdk';
import * as globalActions from '@commercetools-frontend/actions-global';
import loadMessages from '../../messages';
// import dotenv from 'dotenv'
// dotenv.config();
// console.log(process.env);
// import AppRoutes from '../../routes/routes';

// Here we split up the main (app) bundle with the actual application business logic.
// Splitting by route is usually recommended and you can potentially have a splitting
// point for each route. More info at https://reactjs.org/docs/code-splitting.html
// const AsyncStaticApplicationRoutes = React.lazy(() =>
//   import('../../routes/static.routes' /* webpackChunkName: "starter-routes" */)
// );

// const AsyncDynamicApplicationRoutes = React.lazy(() =>
//   import('../../routes/dynamic.routes' /* webpackChunkName: "starter-routes" */)
// );

const AppRoutes = React.lazy(() => import('../../routes/routes'));

// Ensure to setup the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener();

// TODO: Refactor this code in a better way to fetch mcApiUrl and projectKey.
const { mcApiUrl, entryPointUriPath } = window.app;
const projectKey = selectProjectKeyFromUrl();
const ROOT_PATH = entryPointUriPath;

let headers = {
  Accept: 'application/json',
};

if (window.app.env === 'development')
  headers.Authorization = `Bearer ${window.sessionStorage.getItem(
    'sessionToken'
  )}`;

const restLink = new RestLink({
  uri: `${mcApiUrl}/proxy/ctp/${projectKey}`,
  headers: headers,
  credentials: 'include',
});

const apolloClient = createApolloClient({
  cache: {
    // Your custom configuration, according to the Apollo cache documentation.
    // https://www.apollographql.com/docs/react/caching/cache-configuration/
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([restLink, apolloClient.link]),
  cache: new InMemoryCache(),
});

const EntryPoint = () => (
  <ApplicationShell
    environment={window.app}
    applicationMessages={loadMessages}
    apolloClient={client}
    onRegisterErrorListeners={({ dispatch }) => {
      Sdk.Get.errorHandler = (error) =>
        globalActions.handleActionError(error, 'sdk')(dispatch);
    }}
  >
    {/* <AsyncApplicationRoutes /> */}
    {/* <AsyncDynamicBundleApplicationRoute /> */}
    <ApolloProvider client={client}>
      <AppRoutes />
    </ApolloProvider>
  </ApplicationShell>
);
EntryPoint.displayName = 'EntryPoint';

export { ROOT_PATH };
export default EntryPoint;
